












import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { findCostBearerDocument } from "@/store/CostBearerDocument/actions";

const CostBearerDocumentModule = namespace("cost-bearer-document");

@Component({
  components: { AbortButton, FscSimpleCard, SaveButton },
})
export default class CostBearerDepositInvoicePdfPreview extends Vue {
  public name = "CostBearerDepositInvoicePdfPreview";

  @CostBearerDocumentModule.Action("findCostBearerDocument")
  public findCostBearerDocument: any;

  @CostBearerDocumentModule.Getter("getMimeType")
  public mimeType: any;

  @CostBearerDocumentModule.Getter("getDataItem")
  public blob: any;

  @CostBearerDocumentModule.Getter("getIsLoading")
  public isLoading: any;

  @Prop()
  public selectedInvoice!: any;

  @Prop()
  public invoiceData!: any;

  private source = "";

  public mounted(): void {
    if (this.selectedInvoice && this.selectedInvoice.id) {
      this.findCostBearerDocument(this.selectedInvoice.id);
    }
  }

  @Watch("blob")
  public onStudentDocumentChange(blob: any) {
    if (blob) {
      this.source =
        "data:" +
        this.mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    }
  }

  @Watch("selectedInvoice")
  public onItemChange(invoice: any): void {
    if (invoice && invoice.id) {
      this.findCostBearerDocument(invoice.id);
    }
  }
}
