

































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import TextDecimal from "@/components/TextDecimal.vue";
import cloneDeep from "lodash/cloneDeep";
import _ from "lodash";

@Component({
  components: {
    Table,
    TextDecimal,
  },
})
export default class CostBearerDepositInvoiceProducts extends Vue {
  public name = "CostBearerDepositInvoiceProducts";

  @Prop()
  public depositInvoiceProducts!: any;

  @Prop({ type: Boolean, required: false, default: () => false })
  public depositInvoiceLoading!: boolean;

  @Prop()
  public selectedExistingInvoiceId!: any;

  public fields = [
    {
      key: "selected",
      label: "",
      class: "w-30",
    },
    {
      key: "date",
      label: this.$t("calendar.form_date"),
      sortable: false,
    },
    {
      key: "time",
      label: this.$t("theory.time"),
      sortable: false,
    },
    {
      key: "productName",
      label: this.$t("roles.description"),
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$t("calendar.class"),
      sortable: false,
    },
    {
      key: "duration",
      label: "Min.",
      sortable: false,
    },
    {
      key: "price",
      label: this.$t("students.amount_to_pay"),
      sortable: false,
    },
  ];

  protected selectedResources: Array<any> = [];
  protected selectedExistingInvoices: Array<any> = [];
  public tableSmall = true;

  protected onSelectedResources(selectedEducations: Array<any>): void {
    const resources = cloneDeep(selectedEducations).map((education: any) => {
      if (education.checked) {
        education.checked = false;
        return education;
      } else {
        education.checked = true;
        education.new = true;
        return education;
      }
    });
    this.selectedResources = resources.concat(this.selectedExistingInvoices);
    const unique = _.uniqBy(this.selectedResources, (e: any) => {
      return e.serviceId;
    });
    const filtered = unique.filter((resource: any) => resource.checked);
    this.$emit("select-resources", filtered);
  }

  protected rowClass(item: any, type: any) {
    if (!item || type !== "row") return;
    if (item.costBearerInvoiceId !== null && this.selectedExistingInvoiceId !== null && item.costBearerInvoiceId == this.selectedExistingInvoiceId) {
      return "table-secondary font-size-15";
    } else {
      return "font-size-15";
    }
  }

  @Watch("selectedExistingInvoiceId", { deep: true, immediate: true })
  public onSelectedExistingInvoiceIdChange(id: any) {
    if (id) {
      this.selectedExistingInvoices = this.depositInvoiceProducts.filter((product: any) => product.costBearerInvoiceId === id);
      this.$emit("existing", this.selectedExistingInvoices);
    }
  }
}
