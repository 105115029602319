import { render, staticRenderFns } from "./CostBearDepositInvoice.vue?vue&type=template&id=84968e4e&scoped=true&"
import script from "./CostBearDepositInvoice.vue?vue&type=script&lang=ts&"
export * from "./CostBearDepositInvoice.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84968e4e",
  null
  
)

export default component.exports