import { render, staticRenderFns } from "./CostBearerDepositInvoiceProducts.vue?vue&type=template&id=1246aedc&scoped=true&"
import script from "./CostBearerDepositInvoiceProducts.vue?vue&type=script&lang=ts&"
export * from "./CostBearerDepositInvoiceProducts.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1246aedc",
  null
  
)

export default component.exports