


































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import TextDecimal from "@/components/TextDecimal.vue";

@Component({
  components: {
    Table,
    TextDecimal,
  },
})
export default class CostBearerExistingProducts extends Vue {
  public name = "CostBearerExistingProducts";

  @Prop()
  public existingProducts!: any;

  @Prop()
  public existingProductsLoading!: any;

  public tableSmall = true;

  public fields = [
    {
      key: "createdOn",
      label: "",
      sortable: false,
    },
    {
      key: "documentType",
      label: "",
      sortable: false,
    },
    {
      key: "invoiceNumber",
      label: "",
      sortable: false,
    },
    {
      key: "amountInEur",
      label: "",
      sortable: false,
    },
    {
      key: "preview",
      label: "",
      sortable: false,
    },
  ];

  public onInvoiceRowClicked(row: any) {
    this.$emit("row-clicked", row.id, row);
  }

  public onPdfRowClicked(row: any) {
    this.$emit("pdf-row-clicked", row);
  }
}
